<template>
  <authorization-layout>
    <template>
      <div v-if="finished" class="has-text-centered mt-6">
        <div class="subtitle" v-if="isNotEfniEvent">
          {{ $t("auth.set_password_success") }}
        </div>
        <div class="subtitle" v-else>
          {{ $t("email_confirmation.success_description_event_without_landing_page") }}
        </div>
        <div class="button is-primary mt-2 px-4" @click="goToLoginPage" v-if="eventId && isNotEfniEvent">
          {{ $t("auth.log_in") }}
        </div>
      </div>
      <form v-else action="." method="POST" @submit.prevent="submit" class="modal-card">
        <div class="modal-card-subtitle mb-2 has-text-centered">
          {{ $t("auth.set_password_title") }}
        </div>
        <template v-if="nameVisible">
          <div class="columns is-mobile is-vcentered mb-0">
            <div class="column is-half">
              <div class="field">
                <label class="label has-text-primary">{{ $t("auth.auth_firstname_placeholder") }}</label>
                <div class="control">
                  <input
                    class="input pl-1"
                    type="text"
                    :placeholder="$t('auth.auth_firstname_placeholder')"
                    v-model="firstName"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="column is-half">
              <!-- Last name field -->
              <div class="field">
                <label class="label has-text-primary">{{ $t("auth.auth_lastname_placeholder") }}</label>
                <div class="control">
                  <input
                    class="input pl-1"
                    type="text"
                    :placeholder="$t('auth.auth_lastname_placeholder')"
                    v-model="lastName"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="field" v-if="email && email.length">
          <label class="label has-text-primary">{{ $t("auth.email") }}</label>
          <div class="control">
            <input class="input pl-1" type="text" :placeholder="$t('auth.email')" v-model="email" :disabled="true"/>
          </div>
        </div>
        <div class="field">
          <label class="label has-text-primary">{{ $t("auth.password") }}</label>
          <div class="control">
            <input class="input is-simple" type="password" :placeholder="$t('auth.password')" v-model="password"/>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.password.required" class="help is-danger">{{ $t("auth.error_password") }}</p>
            <p v-if="!$v.password.minLength" class="help is-danger">{{ $t("common.field_too_short") }}</p>
          </template>
        </div>
        <div class="field">
          <label class="label has-text-primary">{{ $t("auth.repeat_password") }}</label>
          <div class="control">
            <input class="input is-simple" type="password" :placeholder="$t('auth.repeat_password')"
                   v-model="repeatPassword"/>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.repeatPassword.sameAsPassword" class="help is-danger">{{
                $t("auth.error_repeat_password")
              }}</p>
          </template>
        </div>

        <!-- Privacy policy and terms of service consent -->
        <div class="field" ref="privacyPolicyField" v-if="termsEnabled">
          <div class="control">
            <label class="checkbox">
              <checkbox class="checkbox-input" v-model="terms"></checkbox>
              <i18n path="auth.terms_and_conditions" tag="span" class="consents" :class="termsClass"
                    @click="terms = !terms">
                <a target="_blank" @click.stop="0"
                   :href="privacyLink">{{ $t("auth.privacy_policy_substring") }}</a>
                <a target="_blank" @click.stop="0" href="https://meetingapplication.com/terms-of-service">{{
                    $t("auth.terms_of_service_substring")
                  }}</a>
              </i18n>
            </label>
          </div>
        </div>

        <!-- GDPR consent -->
        <div class="field mb-3" ref="gdprField" v-if="termsEnabled">
          <div class="control">
            <label class="checkbox consents">
              <checkbox class="checkbox-input" v-model="gdpr"></checkbox>
              <i18n path="auth.gdpr_terms" tag="span" class="consents" :class="gdprClass" @click="gdpr = !gdpr">
                <a target="_blank" @click.stop="0" href="https://meetingapplication.com/gdpr/">
                  {{ $t("auth.read_more") }}
                </a>
              </i18n>
            </label>
          </div>
        </div>

        <b-loading :active="isRequesting"></b-loading>

        <button class="button is-primary is-fullwidth mt-1" type="submit" :disabled="isRequesting">
          {{ $t("auth.set_password") }}
        </button>

        <button class="button is-fullwidth mt-1" @click="goToMainPage" type="button" v-if="eventId && isNotEfniEvent">
          {{ $t("common.go_to_main_page") }}
        </button>
      </form>
    </template>
  </authorization-layout>
</template>

<script>
import {mapActions, mapState} from "vuex";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import AuthorizationLayout from "@/web/components/shared/layout/AuthorizationLayout";
import RequestMixin from "@/shared/mixins/RequestMixin";
import UnauthorizedRouteMixin from "@/web/mixins/UnauthorizedRouteMixin";
import Constants from "@/web/constants";
import ResetPasswordErrorModal from "@/web/views/modals/ResetPasswordErrorModal";
import Checkbox from "@/shared/components/form/Checkbox";
import { pluckErrorCode } from "@/shared/utils";

export default {
  name: "ResetPassword",

  components: {AuthorizationLayout, Checkbox},

  mixins: [RequestMixin, UnauthorizedRouteMixin],

  data() {
    return {
      submitted: false,
      password: "",
      repeatPassword: "",
      gdpr: false,
      terms: false,
      finished: false,
    };
  },

  validations() {
    let validations = {
      password: {required, minLength: minLength(7)},
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    };
    if(this.termsEnabled) {
      validations.gdpr = { accepted };
      validations.terms = { accepted };
    }
    return validations;
  },

  computed: {
    ...mapState(["eventId"]),

    privacyLink() {
      if(this.eventId === 1422) {
        return this.$t("custom.instytut_privacy_policy");
      } else {
        return "https://meetingapplication.com/privacy";
      }
    },

    isNotEfniEvent() {
      return this.eventId !== 1185 && this.eventId !== 1233;
    },

    firstName() {
      return this.$route.query.first_name;
    },

    lastName() {
      return this.$route.query.last_name;
    },

    nameVisible() {
      return false;
      // return !!(this.firstName && this.firstName.length && this.lastName && this.lastName.length);
    },

    email() {
      return this.$route.query.email;
    },

    resetPasswordToken() {
      return this.$route.query.token;
    },

    token() {
      return this.$route.query.token;
    },

    termsEnabled() {
      return this.$route.query.terms;
    },

    gdprClass() {
      if (this.submitted && !this.$v.gdpr.accepted) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    termsClass() {
      if (this.submitted && !this.$v.terms.accepted) {
        return "has-text-danger";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("auth", ["updateUserPasswordWithToken"]),

    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const {password, repeatPassword} = this.$data;

        this.save(this.token, password, repeatPassword);
      }
    },

    goToLoginPage() {
      this.$router.replace({name: Constants.ROUTE_LOGIN});
    },

    goToMainPage() {
      this.$router.replace({name: Constants.ROUTE_MAIN_INTRODUCTION});
    }
  },

  requests: {
    async save(token, password, repeatPassword) {
      await this.updateUserPasswordWithToken({
        token: token,
        newPassword: password,
        newPasswordConfirmation: repeatPassword,
      }).then(result => {
        this.finished = true;
      });
    },
  },

  watch: {
    error(newVal) {
      if (newVal) {
        const errorCode = pluckErrorCode(newVal);
        if (errorCode === Constants.REST_RESET_PASSWORD_TOKEN_EXPIRED) {
          this.$buefy.modal.open({
            parent: this,
            component: ResetPasswordErrorModal,
            hasModalCard: true,
            canCancel: true,
            trapFocus: true,
          });
        } else {
          this.$root.openErrorModal(newVal);
        }

        this.error = null;
      }
    },

    token: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          this.$router.replace({name: Constants.ROUTE_MAIN_INTRODUCTION});
        }
      },
    },
  },
};

function accepted(value) {
  return !!value;
}
</script>

<style scoped>
.title {
  font-size: 20px;
}

.subtitle {
  white-space: pre-line;
}

.consents {
  font-size: 13px;
}

.gdpr-label {
  margin-left: 27px;
}

</style>
